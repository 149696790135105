<template>
  <div class="custom-absolute-loading" v-if="!isReady">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>

  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :defaultData="defaultData"
    pathPrefix="main"
    v-else
  />
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

import FormPage from "@/components/penal-retail-helpers/FormPage";

import formConfig from "@/helpers/casesFormConfig";

let collectionName = "cases";

export default {
  components: {
    FormPage,
  },
  watch: {
    customer() {},
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    isReady() {
      return !!this.customers && !!this.establishments;
    },
    defaultData() {
      let defaultCustomer =
        this.isCustomer || this.isSuperCustomer
          ? this.$store.state.AppActiveUser.customer
          : undefined;
      let defaultEstablishment =
        this.isCustomer &&
        this.$store.state.AppActiveUser.establishments.length === 1
          ? this.establishments
              .map((x) => ({ id: x.id, alias: x.alias }))
              .find(
                (x) =>
                  x.id === this.$store.state.AppActiveUser.establishments[0]
              )
          : undefined;

      return {
        customer: defaultCustomer,
        establishment: defaultEstablishment,
        minors: false,
      };
    },
  },
  created() {
    if (this.isCustomer || this.isSuperCustomer) {
      let customerId = this.$store.state.AppActiveUser.customer.id;
      db.collection("customers")
        .doc(customerId)
        .get()
        .then((ref) => {
          this.customers = [{ id: ref.id, ...ref.data() }];
        });
      if (this.isSuperCustomer) {
        this.$bind(
          "establishments",
          db
            .collection("establishments")
            .where("customer.id", "==", customerId)
            .where("deleted", "==", false)
            .orderBy("alias"),
          { wait: true }
        );
      } else {
        this.$bind(
          "establishments",
          db
            .collection("establishments")
            .where("customer.id", "==", customerId)
            .where(
              firebase.firestore.FieldPath.documentId(),
              "in",
              this.$store.state.AppActiveUser.establishments
            )
            .where("deleted", "==", false),
          { wait: true }
        );
      }
    } else {
      this.$bind(
        "customers",
        db
          .collection("customers")
          .where("deleted", "==", false)
          .orderBy("alias"),
        { wait: true }
      );
      this.$bind(
        "establishments",
        db
          .collection("establishments")
          .where("deleted", "==", false)
          .orderBy("alias"),
        { wait: true }
      );
    }
  },
  data() {
    const rows = formConfig(this, this.$store.state.AppActiveUser.role, true);

    return {
      customers: null,
      establishments: null,
      collectionName,

      tabs: [{ name: `${collectionName}.tabs.general`, rows }],
    };
  },
  methods: {
    prevalidate() {
      return false;
    },
  },
};
</script>
